<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import userMenuItems from '@/views/components/sub-menu/settings-sub-menu/users'
import roleModule from '@/store/role'
import store from '@/store'

export default {
  name: 'Roles',
  created() {
    this.$emit('updateMenu', 'settings-users-roles')
  },
  setup() {
    const { menuItems } = userMenuItems('settings-users-roles')
    const MODULE_NAME = 'roles'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, roleModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      menuItems,
    }
  },
}
</script>

<style scoped>

</style>
