import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = 'roles'
const duplicate = (ctx, data) => axios.get(`${endpoint}/${data.id}/duplicate`)
const getActiveRolesList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  queryParams = { ...queryParams, ...{ order_column: 'name', status: 'active' } }
  axios.get(`/${endpoint}`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const Actions = crudActions(endpoint)
export default {
  ...Actions,
  getActiveRolesList,
  duplicate,
}
