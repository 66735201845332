export default {
  GET(state, data) {
    state.role = { ...data }
  },
  LIST(state, data) {
    state.roles = data
  },
  SET_FORM(state, data) {
    state.roleForm = { ...data }
  },
}
